import { Card } from "@clipboard-health/ui-components";
import { ExternalLink, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
// TODO: Use our own icon set from CbhIcon
// eslint-disable-next-line no-restricted-imports
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
// TODO: Use our own Button
// eslint-disable-next-line no-restricted-imports
import { Button, CardContent, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type Worker } from "@src/appV2/Worker/api/types";
import { AxiosError } from "axios";
import React from "react";

import { useToast } from "../../../lib";
import { useUploadResume } from "../api/useUploadResume";
import { ACCEPTABLE_RESUME_UPLOAD_FILE_CONTENT_TYPES } from "../constants";
import { type PlacementCandidate } from "../types";
import { CreateCandidateResumeModal } from "./CreateCandidateResumeModal";
import { DeleteCandidateResumeModal } from "./DeleteCandidateResumeModal";
import { PendingResume } from "./PendingResume";
import { PortResumeDialog } from "./PortResumeDialog";

interface UploadCandidateResumeProps {
  placementCandidate: PlacementCandidate;
  onResumeUploaded: () => Promise<void>;
  onResumeDeleted: () => Promise<void>;
  worker: Worker;
}

export function UploadCandidateResume(props: UploadCandidateResumeProps) {
  const { placementCandidate, onResumeUploaded, onResumeDeleted, worker } = props;
  const { showErrorToast } = useToast();
  const { mutateAsync: uploadResume, isLoading: isUploadingResume } = useUploadResume();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const createResumeModalState = useModalState();
  const deleteResumeModalState = useModalState();

  const handleUploadResume = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDefined(placementCandidate)) {
      return;
    }

    const file = event.target.files?.[0];
    if (!file) {
      showErrorToast("No file selected");
      return;
    }

    if (!ACCEPTABLE_RESUME_UPLOAD_FILE_CONTENT_TYPES.includes(file.type)) {
      logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_RESUME_UPLOAD_INVALID_FILE_TYPE, {
        fileType: file.type,
        fileName: file.name,
        placementCandidateId: placementCandidate.id,
      });
      showErrorToast("Please upload a valid resume file in PDF, JPEG, or PNG format.");
      return;
    }

    try {
      await uploadResume({
        file,
        placementCandidateId: placementCandidate.id,
      });
      await onResumeUploaded();
    } catch (error) {
      if (error instanceof AxiosError) {
        showErrorToast(error.message);
      } else {
        showErrorToast("Failed to upload your resume");
      }
    }
  };

  return (
    <Card variant="tertiary">
      <PortResumeDialog
        placementCandidate={placementCandidate}
        worker={worker}
        onResumeUploaded={onResumeUploaded}
      />
      <CardContent>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Text variant="h5" color="text.primary">
            Resume
          </Text>

          <Stack direction="row" alignItems="center" spacing={1} sx={{ flexShrink: 0 }}>
            <Button
              component="label"
              color="info"
              startIcon={
                placementCandidate.resume?.resumeUrl ? (
                  <RefreshRoundedIcon fontSize="small" />
                ) : undefined
              }
              variant="text"
              disabled={isUploadingResume}
              size="small"
              sx={{
                padding: 1,
              }}
            >
              {placementCandidate.resume?.resumeUrl ? "Re-upload" : "Upload resume"}
              <input
                ref={fileInputRef}
                type="file"
                style={{
                  clip: "rect(0 0 0 0)",
                  clipPath: "inset(50%)",
                  height: 1,
                  overflow: "hidden",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  whiteSpace: "nowrap",
                  width: 1,
                }}
                onClick={() => {
                  // Reset the value when clicking to ensure onChange always triggers
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                }}
                onChange={handleUploadResume}
              />
            </Button>

            {placementCandidate.resume?.resumeUrl ? (
              <>
                <ExternalLink to={placementCandidate.resume?.resumeUrl} variant="body2">
                  View
                </ExternalLink>
                <Button
                  variant="text"
                  color="error"
                  component="label"
                  size="small"
                  sx={{
                    textDecoration: "underline",
                    fontWeight: 400,
                    padding: 1,
                  }}
                  onClick={deleteResumeModalState.openModal}
                >
                  Delete
                </Button>
                <DeleteCandidateResumeModal
                  placementCandidate={placementCandidate}
                  modalState={deleteResumeModalState}
                  onResumeDeleted={onResumeDeleted}
                />
              </>
            ) : (
              <>
                <Button
                  variant="text"
                  color="info"
                  component="label"
                  size="small"
                  sx={{
                    padding: 1,
                  }}
                  onClick={() => {
                    createResumeModalState.openModal();
                    logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_CREATE_RESUME_CLICKED, {
                      placementCandidateId: placementCandidate.id,
                    });
                  }}
                >
                  Create resume
                </Button>
                <CreateCandidateResumeModal
                  placementCandidate={placementCandidate}
                  modalState={createResumeModalState}
                  onResumeUploaded={onResumeUploaded}
                />
              </>
            )}
          </Stack>
        </Stack>
        <Text variant="body2">
          Upload your latest resume in PDF, JPEG, or PNG format to help us match you with the right
          job.
        </Text>
        <PendingResume placementCandidate={placementCandidate} refreshCallback={onResumeUploaded} />
      </CardContent>
    </Card>
  );
}
