import { type IconType } from "@clipboard-health/ui-components";

import { JobType, ShiftNameType } from "./types";

export const MILE_TO_METER = 1609.34;

export const jobTypeOptions = [
  { label: "Full-time", value: JobType.FULL_TIME },
  { label: "Part-time", value: JobType.PART_TIME },
  { label: "PRN", value: JobType.PER_DIEM },
  { label: "Weekend Warrior", value: JobType.WEEKEND_WARRIOR },
];

export const shiftTypeOptions: Array<{
  label: string;
  value: ShiftNameType;
  icon: IconType;
}> = [
  { label: "Day", value: ShiftNameType.AM, icon: "sun-filled" },
  { label: "Evening", value: ShiftNameType.PM, icon: "sunset-filled" },
  { label: "Overnight", value: ShiftNameType.NOC, icon: "moon-filled" },
];

export const ACCEPTABLE_RESUME_UPLOAD_FILE_CONTENT_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];
