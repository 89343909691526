import { StickyScrollPageHeader } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent, Link } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs/FullScreen";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { useCreateOrGetPlacementCandidate } from "../api/useCreateOrGetPlacementCandidate";
import { PlacementCandidateStatus } from "../types";
import { PlacementCandidateError } from "./PlacementCandidateError";
import { PlacementCandidateOnboardingContainer } from "./PlacementCandidateOnboardingContainer";
import { PlacementCandidateSkeleton } from "./PlacementCandidateSkeleton";

interface PlacementCandidateProfileModalProps {
  modalState: UseModalState;
  onClose: () => void;
}

export function PlacementCandidateProfileModal(props: PlacementCandidateProfileModalProps) {
  const { modalState, onClose } = props;

  const worker = useDefinedWorker();

  const {
    data: placementCandidate,
    isLoading: isPlacementCandidateLoading,
    isSuccess: isPlacementCandidateSuccess,
  } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId), staleTime: 60 * 1000 }
  );

  if (isPlacementCandidateLoading) {
    return <PlacementCandidateSkeleton />;
  }

  if (!isPlacementCandidateSuccess || !isDefined(placementCandidate.id)) {
    return <PlacementCandidateError />;
  }

  const isActivatedCandidate = placementCandidate.status === PlacementCandidateStatus.ACTIVATED;

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <StickyScrollPageHeader
        title={isActivatedCandidate ? "Update profile" : "Add your resume"}
        navigateBack={() => {
          onClose();
        }}
        LinkComponent={Link}
      />
      <DialogContent>
        <PlacementCandidateOnboardingContainer
          placementCandidate={placementCandidate}
          worker={worker}
        />
      </DialogContent>
    </FullScreenDialog>
  );
}
