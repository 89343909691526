import { ToggleButton, ToggleButtonGroup } from "@clipboard-health/ui-components";

interface Option {
  label: string;
  value: string;
}

interface PreferenceToggleButtonProps {
  options: Option[];
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
}

export function PreferenceToggleButton(props: PreferenceToggleButtonProps) {
  const { options, value, disabled, onChange } = props;
  const handleChange = (_event: React.MouseEvent<HTMLElement>, newValue: string[]) => {
    onChange(newValue);
  };

  return (
    <ToggleButtonGroup
      value={value}
      sx={{
        flexWrap: "wrap",
        justifyContent: "flex-start",
        gap: 1,
      }}
      disabled={disabled}
      onChange={handleChange}
    >
      {options.map((option) => (
        <ToggleButton key={option.value} value={option.value}>
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
