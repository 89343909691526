import { Text } from "@clipboard-health/ui-react";
// TODO: Use our own icon set from CbhIcon
// eslint-disable-next-line no-restricted-imports
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Stack } from "@mui/material";

export function PlacementCandidateError() {
  return (
    <Stack
      spacing={2}
      sx={{ p: 2 }}
      flex={1}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <WarningRoundedIcon color="warning" fontSize="large" />
      <Text variant="body2">
        Error while loading your candidate profile. Please pull to reload.
      </Text>
    </Stack>
  );
}
