import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics/events";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useUpdatePlacementCandidate } from "../api/useUpdatePlacementCandidate";
import { type PlacementCandidate, type PlacementCandidateMarketingLinkClicks } from "../types";

const MARKETING_LINK_URL_PARAM = "placementsMarketingSource";
const TRACKED_MARKETING_LINK_SOURCES = new Set(["notYetActivated", "notYetSubmittedApplication"]);

function getUpdatedMarketingClicks(
  // eslint-disable-next-line @typescript-eslint/ban-types
  source: string | null | undefined,
  currentMarketingLinkClicks: PlacementCandidateMarketingLinkClicks
): PlacementCandidateMarketingLinkClicks | undefined {
  if (!source || !TRACKED_MARKETING_LINK_SOURCES.has(source)) {
    return undefined;
  }

  const updatedClicks: Record<string, number | undefined> = {
    ...currentMarketingLinkClicks,
  };

  updatedClicks[source] = (updatedClicks[source] ?? 0) + 1;

  return updatedClicks;
}

export function useTrackMarketingLinkClicks({
  placementCandidate,
  workplaceId,
  placementId,
  enabled,
}: {
  placementCandidate?: Pick<PlacementCandidate, "id" | "status" | "marketingLinkClicks" | "worker">;
  workplaceId?: string | undefined;
  placementId?: string | undefined;
  enabled: boolean;
}) {
  const history = useHistory();

  const { mutateAsync: updatePlacementCandidate } = useUpdatePlacementCandidate();

  useEffect(() => {
    if (!placementCandidate || !enabled) {
      return;
    }

    const { id, marketingLinkClicks, worker, status } = placementCandidate;

    const searchParams = new URLSearchParams(history.location.search);
    const source = searchParams.get(MARKETING_LINK_URL_PARAM);

    if (!isDefined(source)) {
      return;
    }

    logEvent(APP_V2_APP_EVENTS.PLACEMENT_MARKETING_LINK_CLICKED, {
      workerId: worker.id,
      placementCandidateId: id,
      workplaceId,
      placementId,
      userType: "AGENT",
      userEmail: worker.email,
      placementCandidateStatus: status,
      linkSource: source,
      // we will eventually add PN and email as possible types here
      sourceType: "SMS",
    });

    // make sure we only process the query param once
    searchParams.delete(MARKETING_LINK_URL_PARAM);
    history.replace({
      search: searchParams.toString(),
    });

    const updatedClicks = getUpdatedMarketingClicks(source, marketingLinkClicks ?? {});

    if (!updatedClicks) {
      return;
    }

    void updatePlacementCandidate({
      placementCandidateId: id,
      marketingLinkClicks: updatedClicks,
    });
  }, [enabled, history, placementCandidate, placementId, updatePlacementCandidate, workplaceId]);
}
