import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { type Worker } from "@src/appV2/Worker/api/types";
import { debounce, isEmpty } from "lodash";
import { useMemo } from "react";

import { APP_V2_APP_EVENTS, useToast } from "../../../lib";
import { logEvent } from "../../../lib/analytics";
import { useCreateOrGetPlacementCandidate } from "../api/useCreateOrGetPlacementCandidate";
import { useUpdatePlacementCandidate } from "../api/useUpdatePlacementCandidate";
import { type PlacementCandidate, PlacementCandidateStatus } from "../types";
import { JobPreferenceForm } from "./JobPreferenceForm";
import { type JobPreferenceFormData } from "./JobPreferenceFormSchema";
import { OnboardingPlacementCandidate } from "./OnboardingPlacementCandidate";
import { PendingPlacementCandidate } from "./PendingPlacementCandidate";
import { UploadCandidateResume } from "./UploadCandidateResume";
import { UploadedCandidateRequirements } from "./UploadedCandidateRequirements";

interface PlacementCandidateOnboardingContainerProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function PlacementCandidateOnboardingContainer(
  props: PlacementCandidateOnboardingContainerProps
) {
  const { placementCandidate, worker } = props;
  const { showSuccessToast } = useToast();
  const { refetch: refetchPlacementCandidate } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );
  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_PAGE_VIEWED,
    {
      placementCandidateId: placementCandidate?.id,
    },
    {
      enabled: isDefined(placementCandidate) && isDefined(placementCandidate.id),
    }
  );
  const { mutateAsync: updatePlacementCandidate } = useUpdatePlacementCandidate();
  const hasIntroductionUrl =
    isDefined(placementCandidate.introductionUrl) && !isEmpty(placementCandidate.introductionUrl);

  const debouncedUpdatePreferences = useMemo(
    () =>
      debounce(async (data: JobPreferenceFormData) => {
        logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_JOB_PREFERENCES_SUBMITTED, {
          placementCandidateId: placementCandidate.id,
          status: placementCandidate.status,
          jobTypes: data.jobTypes,
          shiftTypes: data.shiftTypes,
          preferredAreas: data.addressObject,
        });

        await updatePlacementCandidate({
          placementCandidateId: placementCandidate.id,
          jobTypesInterested: data.jobTypes,
          shiftTypesInterested: data.shiftTypes,
          preferredAreas: data.addressObject ? [data.addressObject] : undefined,
        });

        showSuccessToast("Your job preferences have been updated.");
        await refetchPlacementCandidate();
      }, 500),
    [
      placementCandidate.id,
      placementCandidate.status,
      refetchPlacementCandidate,
      updatePlacementCandidate,
      showSuccessToast,
    ]
  );
  const showPreferences = placementCandidate.status === PlacementCandidateStatus.ACTIVATED;

  return (
    <>
      <PullToRefresh onRefresh={refetchPlacementCandidate} />
      <Stack sx={{ overflowY: "auto" }} flex={1} spacing={2}>
        <Stack flex={1}>
          <Stack spacing={3}>
            <UploadCandidateResume
              placementCandidate={placementCandidate}
              worker={worker}
              onResumeUploaded={async () => {
                await refetchPlacementCandidate();
                showSuccessToast("Resume uploaded successfully");
              }}
              onResumeDeleted={async () => {
                await refetchPlacementCandidate();
                showSuccessToast("Resume deleted successfully");
              }}
            />

            {showPreferences && (
              <JobPreferenceForm
                initialJobTypes={placementCandidate.jobTypesInterested}
                initialShiftTypes={placementCandidate.shiftTypesInterested}
                initialAddressDetails={
                  placementCandidate.preferredAreas?.[0] ??
                  (isDefined(worker.address) && isDefined(worker.geoLocation)
                    ? {
                        address: worker.address,
                        location: {
                          type: "Point",
                          coordinates: [
                            worker.geoLocation.coordinates[0],
                            worker.geoLocation.coordinates[1],
                          ],
                        },
                        distance: worker.preference.distance,
                      }
                    : undefined)
                }
                worker={worker}
                onPreferencesUpdated={debouncedUpdatePreferences}
              />
            )}

            {!hasIntroductionUrl &&
              ((isDefined(placementCandidate.jobTypesInterested) &&
                placementCandidate.jobTypesInterested.length > 0) ||
                (isDefined(placementCandidate.shiftTypesInterested) &&
                  placementCandidate.shiftTypesInterested.length > 0)) && (
                <OnboardingPlacementCandidate
                  placementCandidateId={placementCandidate.id}
                  worker={worker}
                />
              )}
            {hasIntroductionUrl && (
              <UploadedCandidateRequirements
                placementCandidateId={placementCandidate.id}
                introductionUrl={placementCandidate.introductionUrl}
                worker={worker}
              />
            )}
          </Stack>
        </Stack>

        {placementCandidate.status === PlacementCandidateStatus.PENDING && (
          <PendingPlacementCandidate />
        )}
      </Stack>
    </>
  );
}
