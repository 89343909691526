import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib";
import {
  CloseIconType,
  FullScreenDialogTitle,
} from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
import { type Worker } from "@src/appV2/Worker/api/types";

import { type PlacementCandidate, PlacementCandidateStatus } from "../types";
import { PlacementCandidateOnboardingContainer } from "./PlacementCandidateOnboardingContainer";

interface PlacementCandidateProfileUpdateDialogProps {
  updateCandidateProfileModalState: UseModalState;
  placementCandidate: PlacementCandidate;
  worker: Worker;
  footer?: React.ReactNode;
}

export function PlacementCandidateProfileUpdateDialog(
  props: PlacementCandidateProfileUpdateDialogProps
) {
  const { updateCandidateProfileModalState, placementCandidate, worker, footer } = props;

  const isActivatedCandidate = placementCandidate.status === PlacementCandidateStatus.ACTIVATED;
  return (
    <FullScreenDialog modalState={updateCandidateProfileModalState}>
      <FullScreenDialogTitle
        closeIconType={CloseIconType.CLOSE}
        onClose={() => {
          updateCandidateProfileModalState.closeModal();
        }}
      >
        {isActivatedCandidate ? "Update profile" : "Add your resume"}
      </FullScreenDialogTitle>
      <DialogContent>
        <PlacementCandidateOnboardingContainer
          placementCandidate={placementCandidate}
          worker={worker}
        />
      </DialogContent>
      {footer}
    </FullScreenDialog>
  );
}
